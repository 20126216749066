Drupal.behaviors.mobilenav = {
  attach: function (context) {
    if (jQuery().slicknav) {
      $('body').once('mobilenav', function () {
        let $menu_base = $('#block-vircabo-virc-lk-menu .lk_menu');
        if ($menu_base.length > 0) {
          let $menu = $menu_base.clone();
          let $menus_other = $('.region-header-right .block-content > .menu > li').clone();
          $menu.append($menus_other);
          $menu.slicknav({
            label: '',
            closeOnClick: true,
            removeClasses: true,
            prependTo: '.header-row-1 .container'
          });
        }
      });
    }
  }
};
